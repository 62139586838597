import { useCallback, useEffect, useMemo } from 'react';
import {
    isAdmin,
    isBroker,
    isBrokerAdmin,
    isBrokerClerk,
    isDriver,
    isHaulier,
    isHaulierAdmin,
    isTrader,
    isTraderAdmin,
} from 'store/individuals/utils';
import { SessionPayload } from 'store/session/session';
import { useAppDispatch, useAppSelector } from '../config/hooks';
import {
    doForgotPassword,
    doResetPassword,
    doSignIn,
    doSignUp,
    doUpdateToken,
    doOauthSignIn,
} from '../store/session/actions';

//FIXME: CHANGE PARAMS TYPE
const useSession = () => {
    const dispatch = useAppDispatch();

    const getToken = useCallback(() => {
        return sessionStorage.getItem('auth') ?? localStorage.getItem('auth');
    }, []);

    const session = useAppSelector((state) => {
        let initState = { ...state.session };
        const token = getToken();
        if (token) {
            initState.auth = JSON.parse(token);
        }
        return initState;
    });

    const customerId = useAppSelector((state) => state.session?.auth?.user?.customerId);

    const userInfo = useAppSelector((state) => state.session?.auth?.user);

    const registerUser = useAppSelector((state) => state.session?.userInfo);

    const signIn = useCallback(
        (credentials: {
            email: string;
            password: string;
            remember: boolean;
            customerId?: string;
            recaptcha?: string;
        }): Promise<SessionPayload> => dispatch(doSignIn(credentials)),
        [dispatch]
    );

    const oauth = useCallback((): Promise<SessionPayload> => dispatch(doOauthSignIn()), [dispatch]);

    const signUp = useCallback((params: any) => dispatch(doSignUp(params)), [dispatch]);

    const updateToken = useCallback(() => {
        if (session.auth && session.auth.refreshToken) {
            console.log('Do update Token with token', session.auth.refreshToken);
            return dispatch(doUpdateToken(session.auth?.refreshToken));
        } else {
            const token = getToken();
            console.log('Do update Token with token in storage', token);
            if (token) {
                return dispatch(doUpdateToken(JSON.parse(token).refreshToken));
            }
        }
    }, [dispatch, getToken, session.auth]);

    const logout = useCallback(() => {
        localStorage.removeItem('auth');
        sessionStorage.removeItem('auth');
        dispatch({ type: 'USER_LOGOUT_SUCCESS', payload: null });
    }, [dispatch]);

    useEffect(() => {
        const token = getToken();
        if (token) {
            try {
                dispatch({ type: 'USER_SIGN_IN_SUCCESS', payload: JSON.parse(token) });
            } catch (error) {
                console.log('error');
            }
        }
    }, [dispatch, getToken]);

    const forgotPassword = useCallback((email: string) => dispatch(doForgotPassword(email)), [dispatch]);

    const resetPassword = useCallback(
        (newPassword: string, token: string) => dispatch(doResetPassword(newPassword, token)),
        [dispatch]
    );

    const isAdminUser = useMemo(() => (userInfo?.role ? isAdmin(userInfo.role) : false), [userInfo]);
    const isHaulierUser = useMemo(() => (userInfo?.role ? isHaulier(userInfo.role) : false), [userInfo]);
    const isHaulierAdminUser = useMemo(() => (userInfo?.role ? isHaulierAdmin(userInfo.role) : false), [userInfo]);
    const isDriverUser = useMemo(() => (userInfo?.role ? isDriver(userInfo.role) : false), [userInfo]);
    const isBrokerUser = useMemo(() => (userInfo?.role ? isBroker(userInfo.role) : false), [userInfo]);
    const isBrokerAdminUser = useMemo(() => (userInfo?.role ? isBrokerAdmin(userInfo.role) : false), [userInfo]);
    const isBrokerClerkUser = useMemo(() => (userInfo?.role ? isBrokerClerk(userInfo.role) : false), [userInfo]);
    const isTraderUser = useMemo(() => (userInfo?.role ? isTrader(userInfo.role) : false), [userInfo]);
    const isTraderAdminUser = useMemo(() => (userInfo?.role ? isTraderAdmin(userInfo.role) : false), [userInfo]);

    return {
        userInfo,
        registerUser,
        signIn,
        signUp,
        oauth,
        logout,
        updateToken,
        forgotPassword,
        resetPassword,
        token: session?.auth?.token,
        isLogged: session.auth?.token && session.auth?.token !== undefined,
        error: session?.error,
        isLoading: session?.isLoading,
        customerId,
        getToken,
        isAdminUser,
        isHaulierUser,
        isHaulierAdminUser,
        isBrokerUser,
        isDriverUser,
        isBrokerAdminUser,
        isBrokerClerkUser,
        isTraderUser,
        isTraderAdminUser,
    };
};

export default useSession;
