import { Checkbox, notification } from 'antd';
import Button from 'components/ui/base/button';
import Container from 'components/ui/base/container';
import Divider from 'components/ui/base/divider';
import Drawer from 'components/ui/base/drawer/Drawer';
import CustomModal from 'components/ui/base/modal/Modal';
import SearchBar from 'components/ui/base/searchbar';
import { H5 } from 'components/ui/base/typography';
import { defaultPagination, PaginatedParams } from 'core/http/pagination';
import useBreadcrumb from 'hooks/useBreadcrumb';
import useIndividuals from 'hooks/useIndividuals';
import useRequest from 'hooks/useRequest';
import useSession from 'hooks/useSession';
import debounce from 'lodash.debounce';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { deleteIndividual, editIndividual } from 'store/individuals/client';
import {
    brokerIndividualTypeLabel,
    driverIndividualTypeLabel,
    Individual,
    IndividualType,
    traderIndividualTypeLabel,
} from 'store/individuals/individual';
import { SelectOption } from '../../components/ui/composed/formSelect/FormSelect';
import { getTableChangeParams, TableChangeParams } from '../../utils/tableHelpers';
import CreateNewIndividual from './components/CreateNewIndividual';
import IndividualDetails from './components/IndividualDetails';
import IndividualsTable from './components/IndividualsTable';
import { resendInvite } from 'store/session/client';
import FilterDropdown from 'components/ui/composed/filter/FilterDropdown';
import { Segment, StyledStatusSegmented } from 'views/declarations/common/dashboard/StatusSegmented';
import { SpaceBetween } from 'components/styles/layout.styles';

const UserManagement: FC = () => {
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [individual, setIndividual] = useState<Individual>();
    const [showAddNewUserDrawer, setShowAddNedUserDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [paginator, setPaginator] = useState<Partial<PaginatedParams>>(defaultPagination);
    const [deleteIds, setDeleteIds] = useState<string[]>([]);
    const { doRequest: doDeleteIndividual } = useRequest(deleteIndividual);
    const { doRequest: doUpdateIndividual } = useRequest(editIndividual);

    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { userInfo } = useSession();
    const { listIndividuals, individuals, isLoading } = useIndividuals({ individualId: userInfo?.individualId });

    const querySearch = async (query: string) => {
        if (query) {
            const params = { query };
            await listIndividuals(params);
        } else {
            await listIndividuals();
        }
    };

    const debouncedSearch = debounce((query: string) => querySearch(query), 500);

    useEffect(() => {
        setBreadcrumbRoutes([
            {
                breadcrumbName: 'User Management',
                path: '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = useCallback(
        (id: string) => individuals.list.find((element) => element.id === id),
        [individuals.list]
    );

    useEffect(() => {
        if (!individuals.list.length && !individuals.pageSize && isLoading !== false) {
            listIndividuals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [individuals, isLoading]);

    const handleDelete = (ids: string[]) => {
        setDeleteIds(ids);
        setDeleteModalVisible(true);
    };

    const handleResendInvite = async (id: string) => {
        try {
            await resendInvite(id);
            notification.success({ message: 'Invitation resent successfully' });
        } catch (error: any) {
            notification.error({ message: error.response.data.message });
        }
    };

    const handleEdit = (id: string) => {
        const user = getUser(id);
        setIndividual(user);
        setShowAddNedUserDrawer(true);
    };

    const onIndividualsTableChange = (params: TableChangeParams) => {
        console.log(params);
        setPaginator((prev) => ({
            ...prev,
            ...getTableChangeParams({ pagination: params.pagination, sorter: params.sorter }),
        }));
        listIndividuals(getTableChangeParams({ pagination: params.pagination, sorter: params.sorter }));
    };

    const handleUpdateRole = async (role: IndividualType, id: string) => {
        const user = getUser(id);
        doUpdateIndividual(id, { ...user, type: role })
            .then(() => {
                notification.success({ message: 'User role has been successfully edited!' });
                listIndividuals(paginator);
            })
            .catch(() => {
                notification.error({ message: 'Failed to update user role.' });
            });
    };

    const handleUpdateStatus = async (status: string, id: string) => {
        const user = getUser(id);
        const _status = status === 'Active' ? true : false;
        doUpdateIndividual(id, { ...user, active: _status })
            .then(() => {
                notification.success({ message: 'User status has been successfully edited!' });
                listIndividuals(paginator);
            })
            .catch(() => {
                notification.error({ message: 'Failed to update user status.' });
            });
    };

    const handleDetails = (id: string) => {
        const user = getUser(id);
        setIndividual(user);
        setShowUserDetails(true);
    };

    const clearIndividualData = () => {
        setIndividual(undefined);
        setShowAddNedUserDrawer(false);
    };

    const clearIndividualDetailsData = () => {
        setIndividual(undefined);
        setShowUserDetails(false);
    };

    const deleteIndividuals = () => {
        setDeleteModalVisible(false);

        Promise.all(deleteIds.map((id) => doDeleteIndividual(id)))
            .then(() => {
                notification.success({
                    message: `${deleteIds.length > 1 ? 'Users' : 'User'} has been successfully deleted!`,
                });
                listIndividuals(paginator);
            })
            .catch(() => {
                notification.error({
                    message: `Failed to delete ${deleteIds.length > 1 ? 'customers' : 'customer'}`,
                });
            });
    };

    const roleSelectOptions: Readonly<SelectOption[]> | undefined = useMemo(() => {
        switch (userInfo?.role) {
            case IndividualType.TRADER_ADMIN:
                return traderIndividualTypeLabel;
            case IndividualType.TRADER_USER:
                return traderIndividualTypeLabel;
            case IndividualType.DRIVER:
                return driverIndividualTypeLabel;
            case IndividualType.HAULIER_ADMIN:
                return driverIndividualTypeLabel;
            case IndividualType.BROKER_ADMIN:
                return brokerIndividualTypeLabel;
            case IndividualType.BROKER_CLERK:
                return brokerIndividualTypeLabel;
            default:
                Error('Invalid individual type');
        }
    }, [userInfo?.role]);

    const handleStatusChange = (value: string | number) => {
        listIndividuals({ ...paginator, active: value === 'active' ? true : false });
    };

    return (
        <>
            <Drawer
                title={!individual ? 'Add New User' : 'Edit User'}
                width={627}
                visible={showAddNewUserDrawer}
                onClose={clearIndividualData}
            >
                <CreateNewIndividual
                    closeDrawer={clearIndividualData}
                    individual={individual}
                    registeredUsers={individuals?.list}
                    paginator={paginator}
                    roleSelectOptions={roleSelectOptions}
                />
            </Drawer>

            <Container>
                <H5>User Management</H5>
                <SpaceBetween style={{ marginTop: '1.6rem' }}>
                    <div style={{ display: 'flex', gap: '15px' }}>
                        <Button size="large" type="primary" onClick={() => setShowAddNedUserDrawer(true)}>
                            Add New User
                        </Button>
                    </div>
                    <StyledStatusSegmented
                        options={[
                            { label: <Segment>active</Segment>, value: 'active' },
                            { label: <Segment>inactive</Segment>, value: 'inactive' },
                        ]}
                        onChange={handleStatusChange}
                    />
                </SpaceBetween>
                <Divider />
                <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
                    <SearchBar
                        onSearch={(value) => debouncedSearch(value)}
                        inputPlaceholder="Search by name, role, email and phone number"
                        onClear={() => {
                            listIndividuals();
                        }}
                        style={{ flexGrow: 1 }}
                    />
                    <FilterDropdown
                        filters={(state, setState) => [
                            {
                                label: 'Role',
                                content: (
                                    <Checkbox.Group
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                        options={[
                                            { label: 'Broker Admin', value: IndividualType.BROKER_ADMIN },
                                            { label: 'Broker Clerk', value: IndividualType.BROKER_CLERK },
                                            { label: 'Trader Admin', value: IndividualType.TRADER_ADMIN },
                                            { label: 'Trader User', value: IndividualType.TRADER_USER },
                                            { label: 'Haulier Admin', value: IndividualType.HAULIER_ADMIN },
                                            { label: 'Driver', value: IndividualType.DRIVER },
                                        ]}
                                        onChange={(values) => {
                                            const params = {
                                                page: 0,
                                                size: paginator.size ?? 10,
                                                type: values as IndividualType[],
                                            };
                                            setState('role', values);
                                            listIndividuals(params);
                                        }}
                                        value={state.role}
                                    />
                                ),
                            },
                        ]}
                    />
                </div>
                <IndividualsTable
                    handleUpdateStatus={handleUpdateStatus}
                    handleUpdateRole={handleUpdateRole}
                    onEdit={handleEdit}
                    onDetails={handleDetails}
                    onDelete={handleDelete}
                    onResendInvite={handleResendInvite}
                    data={individuals}
                    loading={isLoading}
                    roleSelectOptions={roleSelectOptions}
                    onChange={onIndividualsTableChange}
                />
            </Container>

            <CustomModal
                title={
                    deleteIds.length > 1 ? (
                        <H5>Do you want to remove these users?</H5>
                    ) : (
                        <H5>Do you want to remove this user?</H5>
                    )
                }
                centered
                visible={deleteModalVisible}
                onOk={deleteIndividuals}
                onCancel={() => setDeleteModalVisible(false)}
                width={762}
                contentText={
                    deleteIds.length > 1
                        ? 'If you remove all these users, you will lose all the information associated to them.'
                        : 'If you remove this user, you will lose all the information associated with him.'
                }
            />
            <Drawer title="User Details" width="627" visible={showUserDetails} onClose={clearIndividualDetailsData}>
                {individual && <IndividualDetails individual={individual} />}
            </Drawer>
        </>
    );
};
export default UserManagement;
