import {
    CarOutlined,
    DollarOutlined,
    HomeOutlined,
    InfoCircleOutlined,
    PaperClipOutlined,
    SettingOutlined,
    TeamOutlined,
    UserOutlined,
    FileAddOutlined,
    FileOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import SuitCaseIcon from 'components/icons/SuitCaseIcon';
import { Link } from 'react-router-dom';
import { DeclarationCountry } from 'store/declarations/enums/common/declaration-country';
import { DeclarationInternalType } from 'store/declarations/enums/common/declaration-internal-type';
import { AISMessageTypes, CDSImportMessageTypes } from '../../../store/declarations/enums/common/declaration-types';

const userManagement: ItemType[] = [
    {
        label: <Link to={{ pathname: '/user-management' }}>User Management</Link>,
        key: 'sidebar-user-management',
        icon: <TeamOutlined />,
    },
];
// Commented out as part of work item #4269 | UI General | hide sections that are not working
// const weighbridges: ItemType[] = [
//     {
//         label: <Link to={{ pathname: '/weighbridges' }}>Weighbridges</Link>,
//         key: 'sidebar-weighbridges',
//         icon: <PartitionOutlined />,
//     },
// ];
const templates: ItemType[] = [
    {
        label: <Link to={{ pathname: '/templates' }}>Templates</Link>,
        key: 'sidebar-templates',
        icon: <FileAddOutlined />,
    },
];
const jobsManagement = (active: boolean) => {
    return [
        {
            label: <Link to={{ pathname: '/jobs' }}>Jobs</Link>,
            key: 'sidebar-jobs',
            icon: active ? <SuitCaseIcon></SuitCaseIcon> : <SuitCaseIcon fill={'#9594A5'}></SuitCaseIcon>,
        },
    ];
};

const customsManagement = [
    {
        label: 'Customs Declaration',
        key: 'sidebar-customs',
        icon: <PaperClipOutlined />,
        children: [
            {
                label: 'Irish Customs',
                key: 'sidebar-irish-customs',
                children: [
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.IMPORT}/products/${AISMessageTypes.H1}`,
                                }}
                            >
                                Product Templates
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-products',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.IMPORT}`,
                                }}
                            >
                                Import (AIS)
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-import',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.EXPORT}`,
                                }}
                            >
                                Export (AES)
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-export',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.ARRIVAL}`,
                                }}
                            >
                                Arrival at Exit
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-export-arrival-at-exit',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.ENS}`,
                                }}
                            >
                                ENS (ICS)
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-ens',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.TEMPORARY}`,
                                }}
                            >
                                TSD
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-tsd',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.PBN}`,
                                }}
                            >
                                PBN
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-pbn',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.NCTS}`,
                                }}
                            >
                                NCTS
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-ncts',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.IRELAND}/${DeclarationInternalType.ETD}`,
                                }}
                            >
                                ETD
                            </Link>
                        ),
                        key: 'sidebar-irish-customs-etd',
                    },
                ],
            },
            {
                label: 'UK Customs',
                key: 'sidebar-uk-customs',
                children: [
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.UK}/${DeclarationInternalType.IMPORT}/products/${CDSImportMessageTypes.H1}`,
                                }}
                            >
                                Product Templates
                            </Link>
                        ),
                        key: 'sidebar-uk-import-customs-products',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.UK}/${DeclarationInternalType.IMPORT}`,
                                }}
                            >
                                Import
                            </Link>
                        ),
                        key: 'sidebar-uk-customs-import',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.UK}/${DeclarationInternalType.EXPORT}`,
                                }}
                            >
                                Export
                            </Link>
                        ),
                        key: 'sidebar-uk-customs-export',
                    },
                    {
                        label: (
                            <Link
                                to={{
                                    pathname: `/customs-declarations/${DeclarationCountry.UK}/${DeclarationInternalType.GVMS}`,
                                }}
                            >
                                GVMS
                            </Link>
                        ),
                        key: 'sidebar-uk-customs-gvms',
                    },
                ],
            },
        ],
    },
];

const cargoJourneysManagement = [
    {
        label: 'Shipments',
        key: 'sidebar-shipments',
        disabled: true,
        icon: <DollarOutlined />,
        children: [
            {
                label: <Link to={{ pathname: '/cargo-journeys' }}>Cargo Journeys</Link>,
                key: 'sidebar-cargo-journeys',
            },
        ],
    },
];

const paymentsManagement = [
    {
        label: 'Payments',
        key: 'sidebar-payments',
        disabled: true,
        icon: <DollarOutlined />,
        children: [
            {
                label: <Link to={{ pathname: '/' }}>Payment Status</Link>,
                key: 'sidebar-payments-status',
            },
            {
                label: <Link to={{ pathname: '/' }}>Payment Requests</Link>,
                key: 'sidebar-payments-requests',
            },
        ],
    },
];

const cargoManagement = [
    {
        label: 'Cargo Management',
        key: 'cargo-management',
        icon: <SettingOutlined />,
        children: [
            {
                label: <Link to={{ pathname: '/cargo-management' }}>Cargos</Link>,
                key: 'sidebar-cargo-management',
            },
        ],
    },
];

const driverManagement = [
    {
        label: 'Drivers',
        key: 'driver-management',
        icon: <CarOutlined />,
        children: [
            {
                label: <Link to={{ pathname: '/drivers/view-map' }}>View Map</Link>,
                disabled: true,
                key: 'sidebar-drivers-map',
            },
            {
                label: <Link to={{ pathname: '/drivers/management' }}>Management</Link>,
                key: 'sidebar-drivers-management',
            },
            {
                label: <Link to={{ pathname: '/vehicles/management' }}>Vehicles</Link>,
                key: 'sidebar-vehicles-management',
            },
        ],
    },
];

const files = {
    label: <Link to={{ pathname: '/shared-files' }}>Shared Files</Link>,
    key: 'shared-files',
    icon: <FileOutlined />,
};

export const sidebarMenuItems = (
    isAdminUser: boolean,
    isDriverUser: boolean,
    isHaulierUser: boolean,
    isBrokerUser: boolean,
    activeKeys: string[]
): ItemType[] => [
    {
        label: <Link to={{ pathname: '/home' }}>Home</Link>,
        key: 'sidebar-home',
        icon: <HomeOutlined />,
    },
    {
        label: <Link to={{ pathname: '/customers' }}>Customers</Link>,
        key: 'sidebar-customers',
        icon: <UserOutlined />,
    },
    ...(isAdminUser ? userManagement : []),
    files,
    ...(!isDriverUser ? jobsManagement(activeKeys && activeKeys[0] === 'sidebar-jobs') : []),
    ...(!isHaulierUser ? templates : []),
    ...(!isHaulierUser ? customsManagement : []),
    //...(!isHaulierUser ? weighbridges : []), Commented out as part of work item #4269 | UI General | hide sections that are not working
    ...(isHaulierUser ? cargoJourneysManagement : []),
    ...(!isBrokerUser ? paymentsManagement : []),
    ...(!isHaulierUser && !isBrokerUser ? cargoManagement : []),
    ...(isHaulierUser ? driverManagement : []),
    {
        label: <Link to={{ pathname: '/settings/personal-information' }}>Settings</Link>,
        key: 'sidebar-settings',
        icon: <InfoCircleOutlined />,
    },
];
